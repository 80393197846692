import React from "react";
import "../styles/Landing.css";
import tutorLogo from "../Assets/tutor-logo.webp";
import arrowImg from "../Assets/back-arrow.webp";

type Props = {
  msisdn: string;
  onMsisdnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errorMsg: string;
  showOtp: boolean;
  onResendClick: () => void;
  counter: number;
  backButton: () => void;
  otp: any;
  inputRefs: any;
  onOTPSubmit: () => void;
  pinInputChange: (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  // showError: boolean;
  onPINInput: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPressSendOTPButton: () => Promise<void>;
  isLoading: boolean;
  subscribeLoading: boolean;
  planId: string;
  artworkMap: any;
};
const Landing = (props: Props) => {
  const artwork = props.artworkMap[props.planId];
  return (
    <>
      <div className="landing-section">
        <div className="landing-content">
          <div className="landing-home-img">
            {artwork ? (
              <img
                src={artwork.src}
                alt={artwork.alt}
                width="375"
                height="464"
              />
            ) : null}
          </div>
          <div className="tutor-landing-form">
            <div className="tutor-form">
              <div className="tutor-form-logo">
                <img
                  src={tutorLogo}
                  alt="form-logo-img"
                  width="90"
                  height=" 44"
                />
              </div>
              {props.showOtp == false && (
                <>
                  <div className="text">Please enter your mobile number</div>

                  <div className="tutor-input">
                    <span>+92</span>
                    <input
                      type="tel"
                      value={props.msisdn}
                      placeholder="3xxxxxxxxx"
                      onChange={props.onMsisdnChange}
                      maxLength={10}
                    />
                  </div>
                </>
              )}

              {/* <p className="error">error</p> */}
              {/* <button className="landing-btn ">Get OTP</button>
              <button className="d-deault-btn">Loading..</button> */}
              {/* Otp */}
              {props.showOtp && (
                <div className="tutor-otp-form">
                  <div className="back-arrow-img">
                    <img
                      src={arrowImg}
                      alt="back-arror-img"
                      width={25}
                      height={25}
                      onClick={props.backButton}
                    />
                  </div>
                  <div className="text">Please enter OTP received via SMS</div>
                  <div className="tutor-input">
                    {props.otp.map((value: any, index: any) => (
                      <input
                        key={index}
                        ref={(el) => (props.inputRefs.current[index] = el)}
                        type="tel"
                        name="one"
                        maxLength={1}
                        value={value}
                        onChange={(e) => props.pinInputChange(index, e)}
                        onKeyDown={(e) => props.onPINInput(index, e)}
                      />
                    ))}
                  </div>
                  {/* <p class="error">error</p>  */}
                  <div className="charges-text">
                    <div
                      className="__resendOtp"
                      style={{
                        display: props.counter > 0 ? "none" : "block",
                      }}
                    >
                      Didn't receive the OTP?{" "}
                      {props.isLoading ? (
                        <span>Resending OTP</span>
                      ) : (
                        <span id="otresendSpan" onClick={props.onResendClick}>
                          Resend
                        </span>
                      )}
                    </div>

                    <div
                      className="otcounter"
                      id="otcounter"
                      style={{
                        display: props.counter > 0 ? "block" : "none",
                      }}
                    >
                      00:
                      {props.counter < 10 ? `0${props.counter}` : props.counter}
                    </div>
                    {/* <div className="__resendOtp">
                    Didn't receive the OTP?{" "}
                    {!isTimerActive && (
                      <span id="otresendSpan" onClick={handleResendClick}>
                        Resend
                      </span>
                    )}
                    {isTimerActive && <span>00:{seconds} </span>}
                  </div> */}
                  </div>
                </div>
              )}

              {props.errorMsg.length > 0 ? (
                <p className="error">{props.errorMsg}</p>
              ) : null}
              {props.showOtp ? (
                <>
                  {props.subscribeLoading ? (
                    <button className="d-deault-btn">Verifying...</button>
                  ) : (
                    <button onClick={props.onOTPSubmit} className="landing-btn">
                      Verify
                    </button>
                  )}
                </>
              ) : (
                <>
                  {props.isLoading ? (
                    <button className="d-deault-btn">Sending Otp...</button>
                  ) : (
                    <button
                      className="landing-btn"
                      onClick={props.onPressSendOTPButton}
                    >
                      Get OTP
                    </button>
                  )}
                </>
              )}

              {/* Opp-End */}
              <div className="charges-text">
                <span>*</span>Only for Rs. 2+Tax/Day (Prepaid)
                {/* & Rs.
                50+Tax/Month (Postpaid) */}
                <span>*</span>
              </div>
              <div className="term-conditon">
                <a href="/privacy-policy" target="_blank">
                  {" "}
                  Privacy Policy{" "}
                </a>
                <div className="dot"></div>
                <a href="/terms" target="_blank">
                  {" "}
                  Terms & Conditions{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
