import "../styles/Terms.css";

function Terms() {
  return (
    <div className="terms_section">
      <div className="container">
        <h2>Terms &amp; Conditions</h2>
        <h3>TutorG Terms of Use</h3>
        Salam/Greetings! and very warm welcome to the Terms of Use (“Terms ”) of TutorG( “TutorG”, “We”, ”Us”, or “Our”). TutorG, the revolutionary learning app developed in collaboration with top edtech platforms in Pakistan. Designed to transform the educational landscape, TutorG offers a comprehensive suite of tools that cater to students of all ages. it's a holistic learning platform that empowers students to take charge of their education. By accessing or using the Platform (defined below) you agree to be bound by these Terms. Your agreement with us includes these Terms, the Privacy Policy available at{" "}
        <a href="https://tutorg.com.pk/privacy-policy" target="_blank">
          https://tutorg.com.pk/privacy-policy
        </a>
        <br /><br />

        <b>
          IF YOU DO NOT AGREE WITH THESE TERMS, THEN PLEASE REFRAIN FROM USING THE PLATFORM. BY ACCESSING OR USING THE PLATFORM, YOU IRREVOCABLY ACCEPT THE AGREEMENT AND AGREE TO ABIDE BY THE SAME (AS UPDATED FROM TIME TO TIME).{" "}
        </b> <br /><br />
        If you have downloaded Our PWA / App from Google Play Store, You will also be subject to Google Play Terms of Service. If there is any conflict between Google Play Terms of Service and this Agreement with respect to your use of the App, then, this Agreement shall prevail.<br /><br />
        <h3>Definitions</h3><br />
        <b>“Subscription”</b> is an access model of the product where subscribers get access to the Portal.
        <br></br>
        <b>“PayG” </b> is an access model of the product where subscribers get access to the premium content.
        <br></br>
        <b>“Subscriber”</b> is a User (defined below) who has paid a subscription fee as defined to access that content.
        <br></br>
        <b>“Personal Information” </b> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person.
        <br></br>

        <b>“Platform”</b> means the website available at{" "}
        <a href="https://tutorg.com.pk" target="_blank">
          https://tutorg.com.pk</a> and any sub domains<b> (“Website”)</b> TUTORG mobile application on Android ( “Application”).
        <br></br>
        <b>“Reward”</b> is an award given to a Subscriber for being a top scorer in a particular Segment.
        <br></br>
        <b>“Territory”</b> shall mean Pakistan.
        <br></br>
        <b>“Billing Provider”</b> shall mean Jazz.
        <br /><br />
        <h3>Parties to the Agreement</h3>
        These Terms describe a contractual agreement between You, the User of the Platform, and TUTORG regarding Your use of the Platform. TUTORG has the right to refuse access in its discretion for any or no reason. Without limiting the foregoing, You specifically acknowledge that TUTORG reserves the right to terminate or limit Your access in the event that TUTORG determines, in its sole discretion, that You have violated the policies of TUTORG or any forum used by You, including the activities that adversely affect the experience of other Users.
        <br /><br />
        <h3>Eligibility Criteria for User(s)</h3>You must be of legal age as applicable in local law or at least 4 years of age to access and use the TUTORG Platform. If You are between the age of 4 to 17 years, your access to the Platform should be permitted by Your parent or legal guardian. You (or Your parent/guardian) must agree to be bound by these Terms; You must be a resident of the country the billing provider you have used to enter is operating in; If You do not meet any or all of these criteria, please DO NOT use the TUTORG Platform.
        <br /><br />

        <h3>General Disclaimers</h3>
        By use of the Platform, You agree that You meet with the Eligibility Criteria as detailed in Clause 2 (above) and that You are fully able and competent to understand and accept this Agreement as a binding contract and to abide by all Terms.
        <br /><br />
        You shall not access the Platform by any other means other than through the interfaces that are provided by TUTORG and not acquire, copy, or monitor any portion of the Platform in any way to reproduce or circumvent the navigational structure or presentation of the Platform, to obtain or attempt to obtain any materials, documents, or information through any means not specifically made available through the Platform.
        <br /><br />
        You shall not host, display, upload, modify, publish, or share any information on the Platform that belongs to another person to which You do not have any right to; or is grossly harmful, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, racially objectionable, disparaging, relating to encouraging money laundering or gambling or otherwise unlawful in any manner; or
        <br /><br />
        engages in any activity that interferes with or disrupts access to the Platform or TUTORG servers / networks; or
        <br /><br />
        falsifies or deletes any author attributions, legal or other notices, proprietary designations, label soft he origin or source of software ,or other material on the Platform.
        <br /><br />
        TUTORG shall not be liable for any misuse of the Personal Information shared by You with Us or a third party on Your profile on the Platform.<br /><br />

        TUTORG reserves the right to change the Terms from time to time, and such changes will be applicable to You if You continue to use the Platform at any time after such changes are posted on the Platform. Where We believe that any changes are material, We will notify You through either an in-app notification, or SMS, or push notifications, or email You have provided Us, but regardless of whether You receive such notice, any changes will become effective if You use any of the Platform at any time after such changes are published on this page. We recommend that you visit this page periodically to be sure You are aware of the most recent Terms.
        <br /><br />
        If You do not agree to these Terms, do not use any of the Platform, and if You do not wish to agree to any changes to these Terms, please cease using the Platform.
        {/* <br /> */}
        <br /><br />
        <h3>Service Subscription and un-subscription:</h3>
        Service Subscription: The service is provided to Billing Provider’s customers at a subscription fee and this subscription can be purchased at {" "}
        <a href="https://tutorg.com.pk/home " target="_blank">
          https://tutorg.com.pk/home
        </a>{" "}
        and other advertising links as promoted by TUTORG<br /><br />
        Subscribers can opt for the following subscription plan(s) to avail access to TUTORG.<br />


        <div className="scrollable-table">
          <table>
            <tr>
              <th>Package </th>
              <th>Charging Model</th>
              <th>Price Points</th>
              <th>Price with tax </th>
              <th>Validity </th>

            </tr>
            <tr>
              <td>Course Videos & Assements</td>
              <td>Daily </td>
              <td>2</td>
              <td>2.39</td>
              <td>1 day</td>

            </tr>
            <tr>
              <td>Live Tutor Class (1-to-Many) Grade 1-8</td>
              <td>Daily</td>
              <td>150</td>
              <td>179.25</td>
              <td>1 day </td>

            </tr>
            <tr>
              <td>Live Tutor Class (1-to-Many) Matric</td>
              <td>Daily</td>
              <td>200</td>
              <td>239.00</td>
              <td>1 day  </td>

            </tr>
            <tr>
              <td>Live Tutor Class (1-to-Many) FSC</td>
              <td>Daily</td>
              <td>250</td>
              <td>239.00</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Olevels</td>
              <td>Daily</td>
              <td>250</td>
              <td>298.75</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Alevels</td>
              <td>Daily</td>
              <td>350</td>
              <td>418.25</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Historical VOD  (1-to-Many) Grade 1-8</td>
              <td>Daily</td>
              <td>2</td>
              <td>2.39</td>
              <td>1 day  </td>
            </tr>


            <tr>
              <td>Historical VOD  (1-to-Many) Matric/FSC</td>
              <td>Daily</td>
              <td>4</td>
              <td>4.78</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Historical VOD  (1-to-Many) Olevels/Alevels</td>
              <td>Daily</td>
              <td>6</td>
              <td>7.17</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Past Papers with Solution (Full Course)</td>
              <td>Pay G</td>
              <td>499</td>
              <td>596.31</td>
              <td>One Time </td>
            </tr>


            <tr>
              <td>Past Papers with Solution (Full Course)</td>
              <td>Pay G</td>
              <td>499</td>
              <td>596.31</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Teachers Notes (Full Course)</td>
              <td>Pay G</td>
              <td>999</td>
              <td>1193.81</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Grade 1-8</td>
              <td>Pay G</td>
              <td>3000</td>
              <td>3585.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Matric</td>
              <td>Pay G</td>
              <td>5000</td>
              <td>5975.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) FSC</td>
              <td>Pay G</td>
              <td>6000</td>
              <td>7170.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Olevels</td>
              <td>Pay G</td>
              <td>7000</td>
              <td>8365.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor Class (1-to-Many) Alevels</td>
              <td>Pay G</td>
              <td>9000</td>
              <td>10755.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor (1to1) Grade 1-8</td>
              <td>Pay G</td>
              <td>300</td>
              <td>358.50</td>
              <td>1 day  </td>
            </tr>


            <tr>
              <td>Live Tutor (1to1) Matric/FSC</td>
              <td>Pay G</td>
              <td>800</td>
              <td>956.00</td>
              <td>1 day  </td>
            </tr>


            <tr>
              <td>Live Tutor (1to1) Olevels</td>
              <td>Pay G</td>
              <td>1000</td>
              <td>1195.00</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Live Tutor (1to1) Alevels</td>
              <td>Pay G</td>
              <td>3000</td>
              <td>3585.00</td>
              <td>1 day  </td>
            </tr>

            <tr>
              <td>Live Tutor (1to1) Grade 1-8 (Full Course)</td>
              <td>Pay G</td>
              <td>7500</td>
              <td>8962.50</td>
              <td>One Time </td>
            </tr>


            <tr>
              <td>Live Tutor (1to1) Matric/FSC (Full Course) </td>
              <td>Pay G</td>
              <td>20000</td>
              <td>23900.00</td>
              <td>One Time </td>
            </tr>



            <tr>
              <td>Live Tutor (1to1) Olevels (Full Course)</td>
              <td>Pay G</td>
              <td>30000</td>
              <td>35850.00</td>
              <td>One Time </td>
            </tr>

            <tr>
              <td>Live Tutor (1to1) Alevels (Full Course)</td>
              <td>Pay G</td>
              <td>90000</td>
              <td>107550.00</td>
              <td>One Time </td>
            </tr>


          </table>
        </div>
        <br />
        <br />
        <h3>Conditions for providing information to Service users:</h3>
        <p>
          During Service activation, the subscriber consents to receiving text messages for new features/updates in TUTORG. Text messages are sent from Billing Provider’s Network which does not incur any charges. TUTORG reserves the right to change the subscription fee or run special offers from time to time in which it can offer discounted or free subscription to Billing Provider’s customers.<br />
          TutorG’s Rights<br />
          TUTORG shall:<br />
          P reserve the right to change the accepted method of payment or billing provider at its sole discretion;<br />
        </p>
        <br />



        <h3>No Guarantees</h3>Among other things, TUTORG does not guarantee that:
        <br />
        The Platform will meet the User’s expectations; or
        <br />
        The Platform will be accessible without interruption or in a timely, reliable, or fault- free manner; or
        <br />
        The results obtained through use of the Platform will be as per users expectations deemed as correct and reliable; or
        <br />
        The quality of the products, services, information, or other material Won or obtained by the User through the Platform will meet the User’s expectations.
        <br />
        The User shall be solely responsible for damages to his or her data system or for loss of data arising from downloads of content from the Platform.
        <br />
        No guidance or information, written or oral, obtained from TUTORG or via the Platform, shall constitute any warranty, which has not specifically been stated in these Terms.
        <br />
        <br />
        <h3>Limitation of Liability</h3>Users specifically acknowledge and agree that in no event shall TUTORG be liable for any direct or indirect damage, including but not limited to, delay, damages caused by unused opportunity of the Platform, loss of profits, goodwill, licenses of data, or other financial loss arising out of: the use of the Platform or inability to use the Platform; costs arisen from acquiring are placement of any product or service that is based on any item, data, information, or service purchased through the Platform, from any message received through the Platform or any transactions made by means of the Platform; third party claims against the subscriber; unlawful access to or modifications of data transmissions; or any other circumstances related to the Platform. <br />
        <br />
        TUTORG exempts itself from any claims for damages relating to the Platform access, function, quality, possible errors such as spelling errors, bugs, and other factors relating to the Platform.
        <br />
        TUTORG shall not be liable for any indirect or unforeseeable damages caused to the Users under any given circumstances.
        <br />
        The User(s) shall maintain confidentiality of his/her User credentials and shall be responsible for any access made through his/her account. The User shall immediately notify TUTORG of any actual or suspected unauthorized use of User’s credentials and may be liable for the TUTORG’s losses or such other parties as the case may be, due to any unauthorized use.
        <br />
        <br />
        <h3>Force Majeure</h3>TUTORG shall not be liable for any damages what so ever arising out of force majeure or other similar circumstances, directly or indirectly affecting TUTORG and unreasonably complicating TUTORG’s activities. Examples of force majeure events are real or potential labour disputes, governmental actions, war or threat of war, sabotage, civil unrest, demonstrations, fire, storm, flooding, explosion, earthquake, provisions or limitations of materials or resources, inability to obtain the relevant authorization, accident, and defect in electricity or telecommunication network. Force majeure or other event beyond TUTORG’s control hindering, delaying or complicating the maintenance of the Platform entitles the TUTORG to suspend or limit the Platform until further notice.
        <br />
        <br />
        <h3>Intellectual Property Rights</h3>The content of the Platform is protected by international copyright acts and treaties. All reproduction or distribution of any material on the Platform, including but not limited to text, photographs, movies, music and software programs is strictly prohibited, unless explicitly stated otherwise. TUTORG reserves any and all rights not expressly granted herein.
        <br />
        TUTORG’s trade name, logotype, and all related trademarks, product and/or service names and slogans used in the Platform are the property of TUTORG and may not be used in any way without the prior written approval of TUTORG. Users use of the Platform shall not be construed as the grant of any license or right to use any trademarks or names appearing on the Platform.
        <br />
        <br />
        <h3>Advertisements</h3>TUTORG may include or offer third party products on the Platform through digital advertisements, whether fetched directly from advertisers or sourced from ad networks TUTORG shall not be liable for any privacy practices or the content shared by the business partners, advertisers, sponsors or other websites to which TUTORG provides links on the Platform. The Users shall be responsible for checking the privacy policy and terms of use of such third- parties before exploring their websites to safeguard their interests.
        <br />
        <br />
        <h3>Term and Termination</h3>
        <h3>Term:</h3>This Agreement is valid until further notice.
        <br />
        TUTORG may at its sole discretion and without prior notice, suspend/ freeze/ terminate the User’s access to the Platform if he/she violates or attempts to violate the security of the Platform including, without limitation:
        <br />
        accessing data not intended for You or logging on to a server or the User is not authorized to use; attempting to probe, scan or test the vulnerability of the system or network or to breach security or authentication measures without authentication; or
        <br />
        accessing or using the Platform without authorization, in violation of these Terms or in violation of applicable law.
        <br />
        Violations of system or network security may result in civil or criminal liability. TUTORG will investigate occurrences that may involve such violations and cooperate with law enforcement authorities in prosecuting Users who are involved in such violations. You agree not to use any device, software, or routine to interfere or attempt to interfere with the proper working of this Platform or any activity being conducted on this Platform.
        <br />
        TUTORG may at its sole discretion and without prior notice, suspend, freeze or terminate the Subscriber Account under the following circumstances:
        <br />
        The Subscriber violates any or all terms of this Agreement or instructions on the Platform;
        <br />
        TUTORG has reason to suspect a crime, misuse, fraud, or attempt of such in relation to use of the Platform;
        <br />
        TUTORG considers it to be necessary for safety reasons or TUTORG’s reputation; or
        <br />
        TUTORG is forced to do so pursuant to legislation or ruling of a competent authority.
        <br />
        <br />
        <h3>Indemnity</h3>The Users shall indemnify, defend, and hold harmless TUTORG and its affiliates, if any, from and against any and all losses, liabilities, claims, suits, proceedings, penalties, interests, damages, demands, costs, and expenses (including legal and statutory fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by TUTORG that arise out of, result from, or in connection with:
        <br />
        the User’s breach of these Terms; <br />
        any claims made by any third party due to, or arising out of, or in connection with User’s use of TUTORG Platform or participation in a Campaign on the Platform or winning/claiming the reward of such Campaign;
        the User’s violation of any rights of another, including intellectual property right and the User’s violation of any applicable laws.

        <br />
        Not with standing anything to the contrary in these Terms, in no event shall TUTORG and its affiliates, if any, be liable to the User or anyone claiming through the User in respect of any subject matter of these Terms.

        <br />
        <br />
        <h3>Applicable Law and Jurisdiction</h3>These Terms shall be governed by the laws of territory. You agree that TUTORG is solely based in Islamabad Pakistan, and nothing shall give rise to personal jurisdiction over us in jurisdictions other than Pakistan and you shall not raise any claims against us in any court or forum in any jurisdiction other than Pakistan
        Any claim or dispute between the Subscriber and TUTORG will be entertained and tried solely and exclusively by a court of competent jurisdiction located in Pakistan and no other place whatsoever.
        <br />
        <br />
        <h3>Severability</h3>If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion there of, to be unenforceable, that portion shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision. The remainder of the Terms shall continue in full force and effect.
        <br />
        <br />
        <h3>Waiver</h3>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by TUTORG. Any consent by TUTORG to, or a waiver by TUTORG of any breach by the User, whether expressed orimplied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.
        <br />
        <br />

      </div>
    </div>
  );
}

export default Terms;
