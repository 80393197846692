// Staging

// export const baseUrl = "https://tutorg.ecnstg.com/tutorg/api/";

// Production

export const baseUrl = "https://tutorg.com.pk/tutorg/api/";

export const username = "tutorgMAPP";

export const password = "In108ze64F1CwhgpuliqB5n";

export const gtmId = "GTM-WJ887SW3";
