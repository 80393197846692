export const ApiNames = {
  generateToken: "generateToken",
  getFlow: "getFlow",
  checkOperator: "checkOperator",
  sendOTP: "sendOtpWeb",
  subscribeWeb: "subscribeWeb",
  ffSubscribe: "ffSubscribe",
  unsubscribeWeb: "unsubscribe",
  loginWeb: "loginWeb",
  utmList: "utmList",
  checkUser: "checkUser",

  //UnUsed endpoints
};
