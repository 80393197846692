import React, { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";
import {
  checkOperatorService,
  checkUtmService,
  getFlowService,
  subscribeService,
  sendOtpService,
} from "../services/LandingService";
import { useNavigate } from "react-router-dom";
import {
  tagManagerEvents,
  tagManagerParamsEvents,
} from "../utils/GoogleTagManager";
import AppMessage from "../constants/AppMessage";
import Landing from "../components/Landing";
import { encode as base64_encode } from "base-64";
import { ApiNames } from "../constants/ApiNames";
import artWork from "../Assets/artwork.webp";
import artWorkPickaboo from "../Assets/artwork-pikaboo.webp";
import artWorkOutclass from "../Assets/artwork-outclass.webp";
import EventsName from "../constants/EventsName";

type Props = {
  token: string;
};

interface Artwork {
  src: string;
  alt: string;
}

let adNetwork: string = "";
let initialHEMsisdn: string = "";
let subPackage: string = "";
let uuid: string = "";

const LandingScreen = (props: Props) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [msisdn, setMsisdn] = useState<string>("");
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscribeLoading, setSubscribeLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const utm_source: any = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";

  const planId: any = searchParams.get("plan_id")
    ? searchParams.get("plan_id")
    : "daily2";

  const artworkMap = {
    daily1: {
      src: artWorkPickaboo,
      alt: "TutorG Pickaboo Daily",
    },
    daily2: {
      src: artWorkOutclass,
      alt: "TutorG Outclass Daily",
    },
    daily3: {
      src: artWork,
      alt: "TutorG KP Daily",
    },
  };
  const deviceInfo = navigator.userAgent;

  useEffect(() => {
    if (props.token) {
      getFlow();
      if (utm_source) checkUtm();
    }
  }, [props.token]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  //   Get Flow
  const getFlow = async () => {
    try {
      const endpoint = `getFlow?utm_source=${utm_source}&plan_id=${planId}&device_info=${deviceInfo}`;
      let response = await getFlowService(endpoint);

      if (response.result.msisdn) {
        initialHEMsisdn = response.result.msisdn.slice(-10);
        setMsisdn(initialHEMsisdn);
        subPackage = response.result.subPackage;
        if (response.result.signIn) {
          login(initialHEMsisdn);
        }
        if (response.result.fastFlow && response.result.otp) {
          let data_ = {
            msisdn: initialHEMsisdn,
            otp: response.result.otp,
            utm_source: utm_source,
            device_info: deviceInfo,
            sub_package: response.result.subPackage,
            plan: planId,
            uuid: response.result.uuid,
          };
          const apiName = response.result.fastFlow
            ? `${ApiNames.ffSubscribe}`
            : ApiNames.subscribeWeb;

          Subscribe(apiName, data_);
        }
      }
    } catch (error) {
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   msisdn

  const onMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    let regex = new RegExp("^(3[0-9]*|)$");

    if (regex.test(input)) {
      setMsisdn(input);
    }
  };

  const onPressSendOTPButton = async () => {
    setErrorMsg("");
    if (msisdn.length === 10) {
      if (initialHEMsisdn === msisdn) {
        sendOTP(msisdn);
      } else {
        checkOperator();
      }
    } else {
      setErrorMsg(AppMessage.msisdnLength);
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await checkOperatorService({ msisdn });
      if (!response.result.isOther) {
        subPackage = response.result.package.toLowerCase();
        sendOTP(msisdn);
      } else {
        setIsLoading(false);
        setErrorMsg(AppMessage.otherOperator);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  const sendOTP = async (num: string) => {
    try {
      setIsLoading(true);
      let _data = {
        msisdn: num,
      };
      let response = await sendOtpService(_data);
      uuid = response.result.uuid;
      if (msisdn === initialHEMsisdn) {
        let arr = response.result.desc.split("");
        setOtp(arr);
      }
      setShowOtp(true);
      setErrorMsg("");
      setCounter(30);
      setIsLoading(false);
    } catch (error) {
      console.log("Error: " + error);
      setShowOtp(false);
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //   Otp
  const pinInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    if (/^\d$/.test(value)) {
      onCodeChange(index, value);
      if (index < inputRefs.current.length - 1) {
        // Move focus to the next input field
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const onCodeChange = (index: number, value: string) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...otp];
      newCode[index] = value;
      setOtp(newCode);
    }
  };

  const onPINInput = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && otp[index] !== "") {
      if (index >= 0) {
        onCodeChange(index, "");
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else {
          inputRefs.current[index]?.focus();
        }
      }
    }
  };

  const onOTPSubmit = () => {
    let _otp = otp.join("");
    if (_otp.length === 4) {
      setErrorMsg("");
      let data_ = {
        msisdn: msisdn,
        otp: _otp,
        utm_source: utm_source,
        device_info: deviceInfo,
        sub_package: subPackage,
        plan: planId,
        uuid: uuid,
      };

      Subscribe(ApiNames.subscribeWeb, data_);
    } else {
      setErrorMsg(AppMessage.invalidOtp);
    }
  };

  const Subscribe = async (endpoint: string, data: any) => {
    try {
      let eventParams = {
        planId: planId.toLowerCase(),
        utm_source: utm_source,
      };
      console.log("eventParams:: ", eventParams);
      setSubscribeLoading(true);
      let response = await subscribeService(endpoint, data);
      if (
        adNetwork.toLowerCase() === "tiktok" ||
        adNetwork.toLowerCase() === "tik tok"
      ) {
        eventParams.utm_source = `tiktok-econ`;
        tagManagerParamsEvents(EventsName.subscribePolicy, eventParams);
      } else {
        tagManagerEvents("subscribe", utm_source);
      }
      let url = window.location.origin;
      let encodedMsisdn = base64_encode(msisdn);
      let encodedPlanId = base64_encode(planId);

      url = `${url}/home?ndsism=${encodedMsisdn}&planid=${encodedPlanId}`;
      if (url.includes("http:")) {
        url = url.replace("http", "https");
      }
      window.location.replace(url);
      setSubscribeLoading(false);
    } catch (error: any) {
      setSubscribeLoading(false);
      if (
        error?.response?.data?.result.toLowerCase() ===
        "otp verification failed"
      ) {
        setErrorMsg(AppMessage.invalidOtp);
      } else {
        setErrorMsg(AppMessage.requestFailed);
      }
    }
  };

  const login = async (msisdn: string) => {
    try {
      let url = window.location.origin;
      let encodedMsisdn = base64_encode(msisdn);
      let encodedPlanId = base64_encode(planId);

      url = `${url}/home?ndsism=${encodedMsisdn}&planid=${encodedPlanId}`;
      if (url.includes("http:")) {
        url = url.replace("http", "https");
      }
      window.location.replace(url);
    } catch (err) {
      console.log("Login Failed. " + err);
    }
  };

  const backButton = () => {
    setOtp(["", "", "", ""]);
    setErrorMsg("");
    setShowOtp(false);
  };
  // Function to resend click
  const onResendClick = async () => {
    try {
      setOtp(["", "", "", ""]);
      setIsLoading(true);

      let _data = {
        msisdn: msisdn,
      };
      let response = await sendOtpService(_data);

      setIsLoading(false);
      setErrorMsg("");
      setCounter(30);
    } catch (error) {
      setIsLoading(false);
      setErrorMsg(AppMessage.requestFailed);
    }
  };

  //Utm
  const checkUtm = async () => {
    try {
      let response = await checkUtmService();
      response.result.map((e: any) => {
        if (utm_source === e.sf_utm) {
          adNetwork = e.ad_network;
        }
      });
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  return (
    <Landing
      msisdn={msisdn}
      errorMsg={errorMsg}
      onMsisdnChange={onMsisdnChange}
      showOtp={showOtp}
      onResendClick={onResendClick}
      counter={counter}
      otp={otp}
      inputRefs={inputRefs}
      pinInputChange={pinInputChange}
      onOTPSubmit={onOTPSubmit}
      onPINInput={onPINInput}
      backButton={backButton}
      onPressSendOTPButton={onPressSendOTPButton}
      isLoading={isLoading}
      subscribeLoading={subscribeLoading}
      planId={planId}
      artworkMap={artworkMap}
    />
  );
};

export default LandingScreen;
