import React, { useEffect, useRef, useState } from "react";
import Home from "../components/Home";

type Props = { unSubscribe: () => Promise<void> };

let videoLink = [
  "https://cdn.econceptionscdn.com/Profera/KP/WhatsApp%20Video%202024-12-05%20at%2015.04.01_0d2750e1.mp4",
  "https://cdn.econceptionscdn.com/Profera/KP/WhatsApp%20Video%202024-12-05%20at%2015.04.02_816b7bf8.mp4",
  "https://cdn.econceptionscdn.com/Profera/Partner1/O Levels/Videos/Mathematics/Unit 1 - Basic Arithmetic/U1 E1 - Introduction.mp4",
  "https://cdn.econceptionscdn.com/Profera/KP/WhatsApp%20Video%202024-12-05%20at%2016.28.51_79e71da3.mp4",
];
const HomeScreen = (props: Props) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [checkuser, setCheckUser] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [showPlayer, setShowPlayer] = useState(false);
  const [planNumber, setPlanNumber] = useState(1);

  useEffect(() => {
    let msisdn: any = localStorage.getItem("msisdn");
    if (msisdn) {
      setCheckUser(true);
    } else {
      setCheckUser(false);
    }
  }, [localStorage.getItem("msisdn")]);

  useEffect(() => {
    const videoElement = videoRef.current;
    const handleTimeUpdate = () => {
      if (videoElement) {
        const currentTime = Math.floor(videoElement.currentTime);
        if (currentTime > 10) {
          videoElement.pause();
          onPressClosePlayer();
          secondhandleShow();
        }
      }
    };

    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, [showPlayer]);

  const subscribe = () => {
    window.location.replace(window.location.origin + "/landing");
  };

  const onPressShowPlayer = () => setShowPlayer(true);
  const onPressClosePlayer = () => setShowPlayer(false);

  const onPressVideo = (val: string) => {
    setVideoSrc(val);
  };

  // Toggle the menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [secondshow, setSecondshow] = useState(false);
  const secondhandleClose = () => setSecondshow(false);
  const secondhandleShow = () => setSecondshow(true);

  return (
    <Home
      checkuser={checkuser}
      unSubscribe={props.unSubscribe}
      subscribe={subscribe}
      videoLink={videoLink}
      toggleMenu={toggleMenu}
      isOpen={isOpen}
      onPressVideo={onPressVideo}
      videoSrc={videoSrc}
      onPressShowPlayer={onPressShowPlayer}
      onPressClosePlayer={onPressClosePlayer}
      showPlayer={showPlayer}
      videoRef={videoRef}
      secondshow={secondshow}
      secondhandleClose={secondhandleClose}
      secondhandleShow={secondhandleShow}
      planNumber={planNumber}
      setPlanNumber={setPlanNumber}
    />
  );
};

export default HomeScreen;
