import React, { useEffect, useRef, useState } from "react";
import "../styles/Modal.css";
import modalimage from "../Assets/modal-left-image.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { usePlanNavigation } from "../utils/CommonFunctions";
import modalTopImg from '../Assets/modal-abs-top-img.webp';
import modalBottomImg from '../Assets/modal-abs-btm-img.webp';

type PlayerProps = {
  show: boolean;
  handleClose: () => void;
  videoSrc: string;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  setPlanNumber: React.Dispatch<React.SetStateAction<number>>;
};

type DownLoadPopUp = {
  secondshow: boolean;
  secondhandleClose: () => void;
  checkuser: boolean;
  planNumber: number;
};

export const VideoPlayerPopUp = (props: PlayerProps) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg" centered>
      <div>
        <div className="ratio ratio-16x9">
          <video
            autoPlay
            ref={props.videoRef}
            muted={false}
            controls
            width="640"
            height="360"
            style={{ border: "1px solid black" }}
          >
            <source src={props.videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Modal>
  );
};

export const DownloadPopUp = (props: DownLoadPopUp) => {
  const planNavigation = usePlanNavigation();
  return (
    <Modal
      show={props.secondshow}
      onHide={props.secondhandleClose}
      keyboard={false}
      size="lg"
      centered
      className="modal-second"
    >
      <Modal.Body>
        <div className="btn-close" onClick={props.secondhandleClose}></div>
        <div className="modal-sec">
          <div className="modal-left">
            <img src={modalimage} alt="" />
          </div>
          <div className="modal-right">
          <div className="ab-r-top-img">
              <img src={modalTopImg} alt="" />
          </div>
          <div className="ab-r-bot-img">
            <img src={modalBottomImg} alt="" />
          </div>
            <div className="modal-title">
              <span>Learn smart,</span> Achieve more, Anytime, Anywhere
            </div>
            <div className="modal-des">
              Your ultimate learning companion for success -tailored courses,
              expert guidance, and more.
            </div>
            {props.checkuser ? (
              <>
                <div className="modal-btn">
                  <a
                    href="https://onelink.to/a63za8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download App
                  </a>
                </div>
              </>
            ) : (
              <>
                <div className="modal-btn">
                  <span
                    onClick={() => {
                      planNavigation(props.planNumber);
                    }}
                  >
                    Subscribe
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
