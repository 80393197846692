import { ApiNames } from "../constants/ApiNames";
// import { fetchLoginPost } from "./FetchAPI";
import { postRequest, getRequest } from "./NetworkService";

export const generateTokenService = async (requestData: {
  username: string;
  password: string;
}) => {
  let { data }: { data: string } = await postRequest(
    ApiNames.generateToken,
    requestData
  );
  return data;
};
