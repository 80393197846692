import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useSearchParams,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Terms from "./components/Terms";
import { username, password, gtmId } from "./utils/Config";
import TagManager from "react-gtm-module";
import { decode as base64_decode } from "base-64";
import LandingScreen from "./screens/LandingScreen";
import { generateTokenService } from "./services/GlobalService";
import AppMessage from "./constants/AppMessage";
import {
  checkUserService,
  unSubscribeService,
} from "./services/LandingService";
import { tagManagerEvents } from "./utils/GoogleTagManager";
import HomeScreen from "./screens/HomeScreen";
import PrivacyPolicy from "./components/PrivacyPolicy";

function App() {
  const [jwtToken, setJwtToken] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const search = useLocation().search;
  const navigate = useNavigate();
  const utm_source: any = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";

  useEffect(() => {
    generateToken();

    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const updateJwtToken = (token: string) => {
    setJwtToken(token);
  };

  const generateToken = async () => {
    try {
      let data = {
        username,
        password,
      };
      let response: any = await generateTokenService(data);
      if (response.result) {
        global.authToken = response.result;
        updateJwtToken(response.result);
        checkUser();
      } else {
        console.log(AppMessage.initializationFailed);
      }
    } catch (e) {
      console.warn("Generate Token Error: ", e);
    }
  };

  const checkUser = () => {
    if (!localStorage.getItem("msisdn")) {
      let url = window.location.href;
      let pagePath = window.location.pathname;
      if (
        pagePath === "/home" &&
        url.includes("?") &&
        url.includes("ndsism=") &&
        url.includes("planid=")
      ) {
        let msisdn = new URLSearchParams(search).get("ndsism");
        let planId = new URLSearchParams(search).get("planid");

        if (planId) {
          planId = base64_decode(planId);
          localStorage.setItem("planId", planId);
        }
        if (msisdn) {
          msisdn = base64_decode(msisdn);
          localStorage.setItem("msisdn", msisdn);
        }
      }
    } else {
      fetchCheckUser();
    }
  };

  const fetchCheckUser = async () => {
    try {
      let msisdn: any = localStorage.getItem("msisdn");
      let planId: any = localStorage.getItem("planId");

      let data = {
        msisdn: msisdn,
        planId: planId,
      };
      let response = await checkUserService(data);

      if (!response.result.active) {
        localStorage.removeItem("msisdn");
        localStorage.removeItem("planId");
        navigate("/landing");
      }
    } catch (err) {
      console.log(AppMessage.initializationFailed);
    }
  };

  const unSubscribe = async () => {
    try {
      let msisdn: any = localStorage.getItem("msisdn");
      let planId: any = localStorage.getItem("planId");
      let data = {
        msisdn: msisdn,
        planId: planId,
      };
      let response = await unSubscribeService(data);
      // Tags
      tagManagerEvents("unsubscribe", utm_source);
      localStorage.removeItem("msisdn");
      localStorage.removeItem("planId");

      navigate("/landing");
    } catch (error) {
      console.warn("unsubcribe error", error);
    }
  };

  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to="/home" replace />} />
        <Route
          path="/home"
          element={<HomeScreen unSubscribe={unSubscribe} />}
        />
        <Route path="/landing" element={<LandingScreen token={jwtToken} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </>
  );
}

export default App;
