import React, { useState, useEffect } from "react";
import "../styles/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../Assets/tutor-logo.webp";
import students from "../Assets/students.webp";
import bannerimg from "../Assets/bannerImg.webp";
import gradefive from "../Assets/grade-five.webp";
import gradesix from "../Assets/grade-six.webp";
import gradeseven from "../Assets/grade-seven.webp";
import gradeeight from "../Assets/grade-eight.webp";
import outclass from "../Assets/outclass.webp";
import knowledgeplatform from "../Assets/knowledge-platform.webp";
import matricpp from "../Assets/matric-pp-icon.webp";
import pastfour from "../Assets/past-four-year.webp";
import learnleftimage from "../Assets/learn-left-image.webp";
import girlimage from "../Assets/girl_icon.webp";
import videoicon from "../Assets/play-icon.webp";
import getbg from "../Assets/groupLesson.webp";
import gradefivepaper from "../Assets/grade-five-paper.webp";
import gradesixpaper from "../Assets/grade-six-paper.webp";
import gradesevenpaper from "../Assets/grade-seven-paper.webp";
import gradeeightpaper from "../Assets/grade-eight-paper.webp";
import lessonbg from "../Assets/groupBg.webp";
import abscircle from "../Assets/abs-circle.webp";
import absrighticon from "../Assets/abs-right-icon.webp";
import studentone from "../Assets/student-1.webp";
import studenttwo from "../Assets/student-2.webp";
import studentthree from "../Assets/student-3.webp";
import studentfour from "../Assets/student-4.webp";
import studentfive from "../Assets/student-5.webp";
import studentsix from "../Assets/student-6.webp";
import testlefticon from "../Assets/test-left-icon.webp";
import studentreview from "../Assets/students-review.webp";
import outclassbox from "../Assets/outclass-box.webp";
import knowledgebox from "../Assets/knowledge-box.webp";
import pikboobox from "../Assets/pikaboo-box.webp";
import studentmobreview from "../Assets/studentmobreview.webp";
import facebook from "../Assets/facebook.webp";
import insta from "../Assets/insta.webp";
import level from "../Assets/olevel.webp";

import grouplessonmb from "../Assets/grouplessons-mb.webp";
import groupbgbml from "../Assets/groupbgmbl.webp";
import menubar from "../Assets/menuBar.webp";
import crossicon from "../Assets/cross.webp";
import Aos from "aos";
import "aos/dist/aos.css";
// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react"; // Correct Swiper imports
import { Navigation, Pagination, Autoplay } from "swiper/modules"; // Modules for Swiper

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { DownloadPopUp, VideoPlayerPopUp } from "./Modal";

type Props = {
  checkuser: boolean;
  unSubscribe: () => Promise<void>;
  videoLink: string[];
  subscribe: () => void;
  toggleMenu: () => void;
  isOpen: boolean;
  onPressVideo: (val: string) => void;
  videoSrc: string;
  onPressShowPlayer: () => void;
  onPressClosePlayer: () => void;
  showPlayer: boolean;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
  secondshow: boolean;
  secondhandleClose: () => void;
  secondhandleShow: () => void;
  planNumber: number;
  setPlanNumber: React.Dispatch<React.SetStateAction<number>>;
};

const Home = (props: Props) => {
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  // Toggle the menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50; // Adjust the scroll threshold as needed
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    document.addEventListener("scroll", handleScroll);
    // AOS
    Aos.init();
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [scrolled]);

  return (
    <div className="home-page">
      {/* header */}

      <div className={`header ${scrolled ? "navbar-scrolled" : ""}`}>
        <div className="container-fluid">
          {/* <div className="container"> */}
          <div className="row">
            <div className="col-md-2 col-6">
              <div className="logo">
                <img src={logo} width={100} height={39} alt="Logo" />
              </div>
            </div>
            <div className={`col-md-8 nav-list ${props.isOpen ? "open" : ""}`}>
              <div className="mob-flex">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
                <div className="crosIcon" onClick={props.toggleMenu}>
                  <img src={crossicon} alt="cross" />
                </div>
              </div>
              <div className="menu">
                <ul>
                  <li>
                    <a href="#" onClick={props.toggleMenu}>
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#course-video" onClick={props.toggleMenu}>
                      Course Videos
                    </a>
                  </li>
                  {/* <li>
                      <a href="#learn-assessment">Assessment</a>
                    </li> */}
                  <li>
                    <a href="#get-slide" onClick={props.toggleMenu}>
                      Live Tutor
                    </a>
                  </li>
                  <li>
                    <a href="#past-paper" onClick={props.toggleMenu}>
                      Past Papers
                    </a>
                  </li>

                  <li>
                    {props.checkuser ? (
                      <div onClick={props.unSubscribe}>
                        <a className="mlb-logout" style={{ cursor: "pointer" }}>
                          Logout
                        </a>
                      </div>
                    ) : (
                      <div onClick={props.subscribe}>
                        <a className="mlb-logout" style={{ cursor: "pointer" }}>
                          Login
                        </a>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2 col-6">
              {props.checkuser ? (
                <div className="d-button" onClick={props.unSubscribe}>
                  <a style={{ color: "white", cursor: "pointer" }}>Logout</a>
                </div>
              ) : (
                <div className="d-button" onClick={props.subscribe}>
                  <a style={{ color: "white", cursor: "pointer" }}>Login</a>
                </div>
              )}
              <div className="menu-bar">
                <img src={menubar} alt="menu bar" onClick={props.toggleMenu} />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      {/* hero-panel */}

      <div className="hero-panel">
        <div className="container-fluid">
          <div className="hero-wraper">
            <div className="container">
              <div className="row">
                <div className="col-md-7">
                  <div
                    className="panel-left"
                    data-aos="fade-right"
                    data-aos-duration="1500"
                  >
                    <div className="panel-heading">
                      Unlock Success with Engaging <span>Lessons</span> & Expert
                      Guidance!
                    </div>

                    <div className="profileing">
                      <div className="profile-img">
                        <img
                          src={students}
                          width={125}
                          height={49}
                          alt="Profile"
                        />
                      </div>

                      <div className="profile-text">
                        Discover the power of <span>personalized learning</span>{" "}
                        with<br></br> expert <span>guidance and engaging</span>{" "}
                        resources.
                      </div>
                    </div>

                    <div className="d-button" onClick={props.secondhandleShow}>
                     <button>Download App</button> 
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div
                    className="panel-right"
                    data-aos="fade-left"
                    data-aos-duration="1500"
                  >
                    <img
                      src={bannerimg}
                      width={451}
                      height={425}
                      alt="Banner-right"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* courses */}

      <VideoPlayerPopUp
        show={props.showPlayer}
        handleClose={props.onPressClosePlayer}
        videoSrc={props.videoSrc}
        videoRef={props.videoRef}
        setPlanNumber={props.setPlanNumber}
      />

      {/* second-modal */}

      <DownloadPopUp
        secondshow={props.secondshow}
        secondhandleClose={props.secondhandleClose}
        checkuser={props.checkuser}
        planNumber={props.planNumber}
      />

      <div
        className="courses-section main-padding"
        id="course-video"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Course Videos</div>
            </div>

            <Swiper
              className="mySwiper"
              slidesPerView={4}
              spaceBetween={60}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                340: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {/*  */}

              <SwiperSlide>
                <div
                  className="slider-content"
                  onClick={() => {
                    props.onPressVideo(props.videoLink[0]);
                    props.onPressShowPlayer();
                    props.setPlanNumber(3);
                  }}
                >
                  <div className="slider-img">
                    <img
                      src={gradefive}
                      width={270}
                      height={208}
                      alt="Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={knowledgeplatform}
                        width={60}
                        height={60}
                        alt="Slider-logo"
                      />
                    </div>
                    <div className="out-cl">Knowledge Platform</div>
                    <div className="grade">Grade 1-8</div>
                    <div className="days">40 Videos</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="slider-content"
                  onClick={() => {
                    props.setPlanNumber(3);
                    props.onPressVideo(props.videoLink[1]);
                    props.onPressShowPlayer();
                  }}
                >
                  <div className="slider-img">
                    <img
                      src={gradesix}
                      width={270}
                      height={208}
                      alt=" Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={knowledgeplatform}
                        width={60}
                        height={60}
                        alt=" slider-logo"
                      />
                    </div>
                    <div className="out-cl">Knowledge Platform</div>
                    <div className="grade">Matric</div>
                    <div className="days">12 Videos</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="slider-content"
                  onClick={() => {
                    props.setPlanNumber(2);
                    props.onPressVideo(props.videoLink[2]);
                    props.onPressShowPlayer();
                  }}
                >
                  <div className="slider-img">
                    <img
                      src={gradeseven}
                      width={270}
                      height={208}
                      alt="Slider_image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={outclass}
                        width={60}
                        height={60}
                        alt="Slider-logo"
                      />
                    </div>
                    <div className="out-cl">Out-Class</div>
                    <div className="grade">Fsc</div>
                    <div className="days">23 Videos</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div
                  className="slider-content"
                  onClick={() => {
                    props.setPlanNumber(2);
                    props.onPressVideo(props.videoLink[3]);
                    props.onPressShowPlayer();
                  }}
                >
                  <div className="slider-img">
                    <img
                      src={gradeeight}
                      width={270}
                      height={208}
                      alt="Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={outclass}
                        width={60}
                        height={60}
                        alt=" Slider-logo"
                      />
                    </div>
                    <div className="out-cl">Out-Class</div>
                    <div className="grade">O-Levels</div>
                    <div className="days">40 Videos</div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* learn */}

      <div className="learn-section" id="learn-assessment">
        <div className="container-fluid">
          <div className="learn-wraper">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="learn-left">
                    <div
                      className="learn-l-text"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      Learn, Practice, Excel- Your Smart Study Buddy!
                    </div>

                    <div
                      className="learn-left-image"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <img
                        src={learnleftimage}
                        width={382}
                        height={69}
                        alt="Learn-right"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="learn-right">
                    <div
                      className="learn-right-text"
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      Our platform provides a dynamic and immersive experience
                      to explore a variety of topics, sparking curiosity and
                      enhancing understanding.
                    </div>
                    <div
                      className="learn-right-des"
                      data-aos="fade-left"
                      data-aos-duration="1500"
                    >
                      We provide a space where you can explore, understand &
                      master new concepts through engaging and interactive
                      experiences, fostering both lifelong learning and personal
                      enrichment.
                    </div>
                  </div>
                </div>
              </div>
              {/* count */}

              <div className="count">
                <div
                  className="girl-img"
                  data-aos="zoom-in"
                  data-aos-duration="2500"
                >
                  <img src={girlimage} width={150} height={151} alt="Girl" />
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div
                      className="count-heading"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      Some Counts That Matters
                    </div>
                  </div>

                  {/* <div className='count-cont'> */}
                  <div className="col-md-3 col-6">
                    <div
                      className="student-count"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      <div className="likes">850K</div>
                      <div className="student-name">Students</div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div
                      className="student-count"
                      data-aos="fade-up"
                      data-aos-duration="1500"
                    >
                      <div className="likes">100+</div>
                      <div className="student-name">
                        Online
                        <br />
                        Courses
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-6">
                    <div
                      className="student-count"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <div className="likes">10K</div>
                      <div className="student-name">Certificates</div>
                    </div>
                  </div>

                  <div className="col-md-3 col-6">
                    <div
                      className="student-count"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <div className="likes">10+</div>
                      <div className="student-name">
                        Years in <br /> Education
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* get */}
      <div
        className="get-section"
        id="get-slide"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div className="container-fluid">
          <div className="get-wraper">
            <img
              className="getbg"
              src={getbg}
              width={1319}
              height={630}
              alt=" Get-bg"
            />
            <img
              className="getbgbl"
              width={316}
              height={288}
              src={grouplessonmb}
              alt="Get-mobile-bg"
            />
            <div className="get-content">
              <div className="get-text">
                Get personalized help with 1-on-1 or group lessons, designed to
                enhance your learning.
              </div>

              <div className="video" onClick={props.secondhandleShow}>
                <div className="video-icon">
                  <img
                    src={videoicon}
                    width={14}
                    height={16}
                    alt=" Video-icon"
                  />
                </div>

                <div className="video-text">Join Now</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* past */}

      <div
        className="courses-section past-section main-padding"
        id="past-paper"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Past Papers</div>
            </div>

            <Swiper
              className="mySwiper"
              slidesPerView={4}
              spaceBetween={60}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              breakpoints={{
                340: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                640: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {/*  */}

              <SwiperSlide>
                <div className="slider-content">
                  <div className="slider-img">
                    <img
                      src={gradefivepaper}
                      width={270}
                      height={208}
                      alt=" Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={matricpp}
                        width={60}
                        height={60}
                        alt=" Slider-logo"
                      />
                    </div>
                    <div className="dot-latest">
                      <div className="dot"></div>
                      <div className="out-cl">Latest</div>
                    </div>
                    <div className="grade">Matric</div>
                    <div className="days">Past 3 years</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slider-content">
                  <div className="slider-img">
                    <img
                      src={gradesixpaper}
                      width={270}
                      height={208}
                      alt="Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={pastfour}
                        width={60}
                        height={60}
                        alt="Slider-logo"
                      />
                    </div>
                    <div className="dot-latest">
                      <div className="dot"></div>
                      <div className="out-cl">Latest</div>
                    </div>
                    <div className="grade">Matric</div>
                    <div className="days">Past 4 years</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slider-content">
                  <div className="slider-img">
                    <img
                      src={gradesevenpaper}
                      width={270}
                      height={208}
                      alt="Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={matricpp}
                        width={60}
                        height={60}
                        alt="Slider-logo"
                      />
                    </div>
                    <div className="dot-latest">
                      <div className="dot"></div>
                      <div className="out-cl">Latest</div>
                    </div>
                    <div className="grade">Fsc</div>
                    <div className="days">Past 2 years</div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slider-content">
                  <div className="slider-img">
                    <img
                      src={gradeeightpaper}
                      width={270}
                      height={208}
                      alt="Slider-image"
                    />
                  </div>
                  <div className="slider-text">
                    <div className="outclass-img">
                      <img
                        src={level}
                        width={60}
                        height={60}
                        alt="Slider-logo"
                      />
                    </div>
                    <div className="dot-latest">
                      <div className="dot"></div>
                      <div className="out-cl">Latest</div>
                    </div>
                    <div className="grade">O-Levels</div>
                    <div className="days">Past 2 years</div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>

      {/* lesson */}
      <div
        className="get-section lesson-section"
        data-aos="zoom-in"
        data-aos-duration="1500"
      >
        <div className="container-fluid">
          <div className="get-wraper">
            <img
              className="getbg lessonbg"
              src={lessonbg}
              width={1319}
              height={631}
              alt="Lesson-bg"
            />
            <img
              className="getbg lessonbgmbl"
              src={groupbgbml}
              width={316}
              height={288}
              alt="Lesson-mobile-bg"
            />

            <div className="get-content">
              <div className="get-text">
                Engaging lessons for Different Grades designed to simplify
                complex topics and make learning easy.
              </div>

              <div className="video" onClick={props.secondhandleShow}>
                <div className="video-icon">
                  <img
                    src={videoicon}
                    width={14}
                    height={16}
                    alt="Video-icon"
                  />
                </div>

                <div className="video-text">View Course Videos</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* success */}

      <div
        className="success-section main-padding"
        data-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="abs-circle">
              <img src={abscircle} width={90} height={92} alt="Success-left" />
            </div>
            <div className="abs-right-icon">
              <img
                src={absrighticon}
                width={169}
                height={172}
                alt="Success-right"
              />
            </div>
            <div className="col-md-12">
              <div className="success-heading">
                Knowledge, confidence, success- all in one place<br></br>
                <span>Your learning journey starts here</span>
              </div>
            </div>

            <div className="swiper-success">
              <Swiper
                navigation={true}
                modules={[Navigation, Autoplay]}
                className="mySwiper success"
                slidesPerView={6}
                spaceBetween={10}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  340: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 15,
                  },
                }}
              >
                {/*  */}

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studenttwo}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentone}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentthree}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentfour}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentfive}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentsix}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={studentfive}
                        width={174}
                        height={175}
                        alt="Slider-image"
                      />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* testimonal */}

      <div
        className="testimonial-section main-padding"
        data-aos="zoom-in-up"
        data-aos-duration="1500"
      >
        <div className="container-fluid">
          <div className="testimonial-bg">
            {/* <div className='container'> */}
            {/* <div className='row'> */}
            {/* <div className='col-md-6'> */}
            <div className="test-left">
              <div className="test-heading">Students Testimonials</div>

              <div className="test-left-icon">
                <img
                  src={testlefticon}
                  width={322}
                  height={309}
                  alt="Test-left"
                />
              </div>
            </div>
            {/* </div> */}
            {/* <div className='col-md-6'> */}
            <div className="test-right">
              <img
                src={studentreview}
                alt="student review"
                width={645}
                height={475}
                className="dis-desk"
              />
              <img
                src={studentmobreview}
                alt="student review"
                className="dis-mobile"
              />
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>

      {/* team */}

      <div
        className="team-section main-padding"
        data-aos="zoom-in-right"
        data-aos-duration="1500"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="main-heading">Our Partners</div>
            </div>
            <div className="swiper-team">
              <Swiper
                className="mySwiper"
                slidesPerView={3}
                spaceBetween={40}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  340: {
                    slidesPerView: 1.5,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                }}
              >
                {/*  */}

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={outclassbox}
                        width={288}
                        height={292}
                        alt="Slider-image"
                      />
                    </div>
                    <div className="slider-text">
                      <div className="team-name">Out-Class</div>

                      <div className="designation">Partner</div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={knowledgebox}
                        width={288}
                        height={292}
                        alt="Slider-image"
                      />
                    </div>
                    <div className="slider-text">
                      <div className="team-name">Knowledge Platform</div>

                      <div className="designation">Partner</div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="slider-content">
                    <div className="slider-img">
                      <img
                        src={pikboobox}
                        width={288}
                        height={292}
                        alt="Slider-image"
                      />
                    </div>
                    <div className="slider-text">
                      <div className="team-name">PikaBoo</div>

                      <div className="designation">Partner</div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}

      <div className="footer" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="footer-logo">
                <img src={logo} width={114} height={44} alt="Logo" />
              </div>
              <div className="footer-des">
                Empowering students with accessible, innovative, and interactive
                learning solutions for a brighter future.
              </div>
            </div>
            <div className="col-md-4 col-6">
              <div className="footer-menu">
                <div className="menu-heading">Navigation</div>
                <ul>
                  <li>
                    <a className="active" href="#">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#course-video">Course Videos</a>
                  </li>

                  <li>
                    <a href="#get-slide">Live Tutor</a>
                  </li>
                  <li>
                    <a href="#past-paper">Past Papers</a>
                  </li>
                </ul>
                <div className="social-icon-mbl">
                  <a
                    href="https://www.facebook.com/tutorG.official"
                    target="_blank"
                  >
                    {" "}
                    <img src={facebook} alt="" />
                  </a>
                  <a
                    href="https://www.instagram.com/official.tutorg/"
                    target="_blank"
                  >
                    <img src={insta} alt="" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="footer-menu">
                <div className="menu-heading">Download</div>
                <ul>
                  <li></li>
                  <li>
                    <a href="https://onelink.to/a63za8" target="_blank">
                      {" "}
                      Android
                    </a>
                  </li>
                  <li>
                    <div className="menu-heading"> Legal</div>
                  </li>
                  <li>
                    <a href="/terms" target="_blank">
                      {" "}
                      Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a href="/privacy-policy" target="_blank">
                      {" "}
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top-footer"></div>

        <div className="footer-icon">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-bottom-text">
                  @ 2024 Tutor G. All rights reserved
                </div>
              </div>

              <div className="col-md-6">
                <div className="footer-socail-icon">
                  <a
                    href="https://www.facebook.com/tutorG.official"
                    target="_blank"
                  >
                    {" "}
                    <img
                      src={facebook}
                      width={19}
                      height={19}
                      alt="Social-icon"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/official.tutorg/"
                    target="_blank"
                  >
                    {" "}
                    <img src={insta} width={19} height={19} alt="Social-icon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
