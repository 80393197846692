import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const getErrorData = (e: unknown) => {
  try {
    if (e instanceof AxiosError && e?.response?.data?.result) {
      return e?.response?.data?.result?.toLowerCase();
    }
  } catch (e) {}
};

export const isPlanMatch = (
  planSubPackage: string,
  subPackage: string | null
): boolean => {
  if (planSubPackage && subPackage) {
    if (planSubPackage.toLowerCase().includes(subPackage.toLowerCase())) {
      return true;
    }
  }

  return false;
};

export const usePlanNavigation = () => {
  const navigate = useNavigate();

  return (packageId: number) => {
    const queryParams = new URLSearchParams({
      plan_id: `daily${packageId}`,
    });
    navigate(`/landing?${queryParams.toString()}`);
  };
};
