const EventsName = {
  //Authentication
  sendOtp: "Send_OTP",

  continueAsGuest: "Continue_As_Guest",
  policyPurchased: "Policy_Purchased",
  policyActivated: "Policy_Activated",
  alreadySubscribed: "Already_Subscribed",
  login: "login",

  // Add Account
  accountAdded: "Account_Added",
  // Claimed
  policyClaimed: "Policy_Claimed",
  claimedPolicyUpdated: "Claimed_Policy_Updated",
  //-----------------------//
  deactivatePolicy: "Deactivate_Policy",
  menuPressed: "Menu_Pressed",
  logout: "LogOut",
  notification: "Notifications",
  policyDetail: "Policy_Details",
  productBenefits: "Product_Benefits",
  planSelected: "Plan_Selected",
  setupProfilePicture: "Setup_Profile_Picture",
  setupProfile: "Setup_Profile",
  services: "Services/Policies",
  policySearched: "Policy_Searched",
  paymentMethod: "Payment_Method",
  customerSupport: "Customer_Support",
  insuranceProvider: "Insurance_Provider",
  insuranceTitle: "Insurance_Title",

  // new
  LoginButton: "Login_button",
  registerOtp: "Register_Otp",
  registerVerify: "Register_Verify",
  DownloadAppButton: "Download_App_Button",
  productClick: "Product_Click",
  partnerclick: "Partner_Click",
  subproductClick: "Sub_Product_Click",
  SubSubProductClick: "Sub_Sub_Product_Click",
  TermsAndConditionsClick: "Terms_And_Conditions_Click",
  NextToAddDetails: "Next_To_Add_Details",
  SubmitToPaymentMethod: "Submit_To_Payment_Method",
  MobileBalancePaymentMethod: "Mobile_Balance_Payment_Method",
  JazzCashPaymentMethod: "JazzCash_Payment_Method",
  SubscriptionOTP: "Subscription_OTP",
  SubscriptionVerify: "Subscription_Verify",
  MessageConfirmation: "Message_Confirmation",

  //// related to acquisition
  subscribePolicy: "Subscribe_Policy",
};

export default EventsName;
