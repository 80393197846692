import "../styles/Terms.css";

function PrivacyPolicy() {
  return (
    <div className="terms_section">
      <div className="container">
        <h2>Privacy Notice For Jazz TutorG</h2>
        <h3>Dear Users,</h3>
        <p>
          Pakistan Mobile Communications Limited “PMCL/Jazz/We/us/Our” provides the Jazz TutorG platform (“TutorG”). Jazz TutorG controls the information we collect for the purposes of applicable laws and regulatory requirements directed by the “Authority” (Pakistan Telecommunication Authority – PTA or any other regulatory body as per laws in Pakistan). <br></br>
          Jazz is committed to providing quality services to you and this Privacy Notice outlines our ongoing obligations to you in respect of how we manage your Personal Information.
        </p>
        <h3>Automatically Collected Information </h3>
        <p>
          Personal Information includes any data that can identify an individual, such as IP addresses or device information, even without direct links to a name or contact details.<br></br>
          This page informs users about our policies on the collection, use and disclosure of Personal Information. By using our Service, you agree to our information collection and use policy. We will not share your information beyond what is described here.<br></br>
          The terms used in this Privacy Notice  have the same meanings as in our Terms and Conditions, which are accessible at Jazz TutorG unless otherwise defined in this Privacy Notice.<br></br>
        </p>
        <h3>Information Collection and Use</h3>
        <p>
          Upon subscribing to and using Jazz TutorG,  we collect your Personal Information such as sign-up information, your device and its technical specification, usage history, associated server logs, your transaction history, traffic data, location and internet data etc. This is set out below in detail as well: <br></br>
          Sign-up information: We collect information you provide when you sign up for a Jazz TutorG account. This includes information such as your name, phone numbers, date of birth, email addresses, profile picture, and password required by us in order to authenticate your identity. When signing up, you may also choose to sync Jazz TutorG with other services, such as your Photos, Videos, music, Documents, contacts, SMS’s, Call log, App-list, x cloud etc. <br></br>
          Log Data: We collect information such as your IP address, device name, and operating system version in case of an error. This helps us improve our services.<br></br>
          Cookies: Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your device internal memory. <br></br>
          This Service does not use these "cookies" explicitly. However, the Jazz TutorG may use third party code and libraries that use "cookies" to collection information and to improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
        </p>
        <h3>Device and technical usage information:</h3>
        <p>
          We collect information you provide when you use Jazz TutorG, such as your customer profile, unique identification code, mobile carrier, photos, videos, music, documents, contacts, SMS’s, Call log, App-list, xclouds: Facebook(pictures & Videos), Gmail, Dropbox, Instagram and time zone setting. We also collect device information such as operating systems, battery, signal strength, device identifiers, settings and software names. We use this data to let you know about your usage statistics, to assist us in enforcing our terms, conditions and policies, to improve Jazz TutorG, and also to administer internal operations (such as for troubleshooting, data analysis, testing, research, statistical and survey purposes), to keep Jazz TutorG safe and secure (including verifying accounts and activity) to comply with legal obligations.
        </p>
        <h3>Your usage information:</h3>
        <p>
          We want to make sure that Jazz TutorG is easy and fun to use so we collect your usage data – such as stats about how you use our app.
        </p>
        <h3>Transaction information:</h3>
        <p>
          we will collect information based on storage plan you purchase via Jazz TutorG so we can tailor Jazz TutorG for you. This type of transaction related data will only be used for value added services including marketing with your consent.
        </p>
        <h3>Location information:</h3>
        <p>
          Jazz TutorG collects information about your location based on one or more of the following:
          <ul>
            <li>Derivations made from the IP address from which you connect; </li>
            <li>the Global Positioning System (GPS) of your device when Jazz TutorG is running in the foreground or background; and/or </li>
            <li>(Together, “Location Information”).</li>
            <li>We use your Location Information to provide you with location based tools and functions (“Location Functions”), provide customized nearby services or value added services (including marketing) (“Location Value Added Services”) on Jazz TutorG that we think you’d like. If you do not wish to share your GPS location with us or use any Location Functions, you can either switch off the GPS functionality on your mobile device.</li>



          </ul>
        </p>
        <h3>Other Ad-Hoc information:</h3>
        <p>
          We may conduct surveys or obtain other feedback, comments and input from you via Jazz TutorG or through websites or other platforms as deemed necessary based on viability. We use this information to understand your needs encircling what is working well, what improvements can be made in order to enhance your user experience and improve Jazz TutorG. we may use such information to provide marketing to you pursuant to Jazz TutorG Marketplace and Marketing section below.
        </p>
        <h3>Aggregated and anonymized Data: </h3>
        <p>
          We will use any of the above information to create aggregated and anonymized data, insights and reports for internal business planning purposes and to understand how to improve Jazz TutorG for our users.<br></br>
          We will also use the above mentioned information to contact you, inform you regarding any new developments, features or other important information about Jazz TutorG. <br></br>
          For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to camera, microphone, accounts, contacts, or phone. The information that we request will be retained by us and used as described in this Privacy Notice. <br></br>
          The Jazz TutorG uses certain third party service(s) that may collect information used to identify you.
        </p>
        <h3>Security of Personal Information</h3>
        <p>
          Your Personal Information is stored in a manner that reasonably protects it from misuse and loss and from unauthorized access, modification or disclosure. <br></br>
          We will collect, use and store your Personal Information as per our legal and regulatory requirements. <br></br>
          It is important to us that your Personal Information is up to date. We will take reasonable steps to make sure that your Personal Information is accurate, complete and up-to-date. If you find that the information we have is not up to date or is inaccurate, please advise us as soon as practicable so we can update our records and ensure we can continue to provide quality services to you.
        </p>
        <h3>Service Providers</h3>
        <p>
          We may employ third-party companies and individuals due to the following reasons: <br></br>
          i. To facilitate our Services; <br></br>
          ii. To provide the Service on our behalf; <br></br>
          iii. To perform Service-related services; or <br></br>
          iv. To assist us in analyzing how our Service is used. <br></br>

          We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.

        </p>
        <h3>Security</h3>
        <p>
          We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
        </p>
        <h3>Links to Other Sites</h3>
        <p>
          This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the privacy notices and policies of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update this Privacy Notice from time to time. Thus, you are advised to review this section periodically for any changes. We will notify you of any changes by posting the new Privacy Notice on this page. These changes are effective immediately after they are posted on this page.
        </p>
        <h3>Contact Us</h3>
        <p>
          If you have any questions or suggestions about our Privacy Notice, do not hesitate to contact us on {" "}
          <a href="mailto:dpo@jazz.com.pk">dpo@jazz.com.pk</a> <br></br>

          Jazz TutorG knows that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This notice describes our privacy policy. <br></br>
          By visiting privacy policy, you are accepting the practices described in this Privacy Notice.
        </p>
        <h3>FAQs</h3>
        <p>
          1. What about Cookies?<br></br>

          2. Does Jazz TutorG share the information it receives? <br></br>

          3. How secure is the information about me? <br></br>
          4. What about third-party advertisers and links to other websites? <br></br>
          5. Which information can I access? <br></br>
          6. Are children allowed to use Jazz TutorG? <br></br>
          7. Conditions of use, notices, and revisions <br></br>
          8. Examples of information collected

        </p>
        <h3>
          1. What Personal Information about Customers does Jazz TutorG gather?
        </h3>
        <p>
          The information we learn from customers helps us personalize and continually improve your Jazz TutorG experience. Here are the types of information we gather. Information You Give Us: We receive and store any information you enter on our Web site/App or give us in any other way. Where applicable, you may choose not to provide certain information, but then you might not be able to take advantage of many of our features. We use the information that you provide for such purposes as responding to your requests, customizing future shopping for you, improving our stores, and communicating with you. <br></br>
          i. Automatic Information: We receive and store certain types of information whenever you interact with us. For example, like many Web sites/Apps, we use "cookies" and we obtain certain types of information when you access Jazz TutorG.<br></br>

          ii. Mobile: When you download or use apps created by Jazz or our subsidiaries, we may receive information about your location and your mobile device, including a unique identifier for your device. We may use this information to provide you with location-based services, such as search results, and other personalized content. Most mobile devices allow you to turn off location services. <br></br>

          iii. E-mail Communications: To help us make e-mails more useful and interesting, we often receive a confirmation when you open e-mail from Jazz TutorG if your computer supports such  capabilities. We also compare our customer list to lists received from other companies, in an effort to avoid sending unnecessary messages to our customers. <br></br>
          iv. Information from Other Sources: We might receive information about you from other sources and add it to our account information.


        </p>
        <h3>2. What About Cookies?</h3>
        <p>
          The Help feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Additionally, you can disable or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or visiting the Web site of its manufacturer. Because cookies allow you to take advantage of some of Jazz TutorG’s essential features, we recommend that you leave them turned on. For instance, if you block or otherwise reject our cookies, you will not be able to use any Jazz TutorG products and services that require you to Sign in.
        </p>
        <h3>3. Does Jazz TutorG Share the Information It Receives?</h3>
        <p>
          Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below and with subsidiaries Jazz TutorG, controls that either are subject to this Privacy Notice or follow practices at least as protective as those described in this Privacy Notice. <br></br>
          i. Affiliated Businesses We Do Not Control: We work closely with affiliated businesses. In other cases, we operate stores, provide services, or sell product lines jointly with businesses like telecom operators. You can tell when a third party is involved in your transactions, and we share customer information related to those transactions with that third party. <br></br>
          ii. Third-Party Service Providers: We employ other companies and individuals to perform functions on our behalf. Examples include fulfilling orders, delivering packages, sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments, and providing customer service. They have access to personal information needed to perform their functions, but may not use it for other purposes. <br></br>
          iii. Promotional Offers: Sometimes we send offers to selected groups of Jazz TutorG customers on behalf of other businesses. When we do this, we do not give that business your name and address. <br></br>
          iv. Protection of Jazz TutorG and Others: We release account and other personal information when we believe release is appropriate to comply with the law; enforce or apply our Conditions of Use and other agreements; or protect the rights, property, or safety of Jazz TutorG, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction. Obviously, however, this does not include selling, renting, sharing, or otherwise disclosing personally identifiable information from customers for commercial purposes in violation of the commitments set forth in this Privacy Notice.
        </p>
        <h3>4. How Secure Is Information About Me?</h3>
        <p>
          i. We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you input. <br></br>
          ii. It is important for you to protect against unauthorized access to your password and to your computer/mobile/device. Be sure to sign off when finished using a shared device.
        </p>
        <h3>
          5. What About Third-Party Advertisers and Links to Other Websites?
        </h3>
        <p>
          We do not have third party advertisers or links to other websites on our platform. However, in case we opt for such third party advertising in future, the same will be covered therein and details will be added accordingly.
        </p>
        <h3>6. Which Information Can I Access?</h3>
        <p>
          Jazz TutorG gives you access to a broad range of information about your account and your interactions with Jazz TutorG for the limited purpose of viewing and, in certain cases, updating that information.
        </p>
        <h3>7. Are Children Allowed to Use Jazz Game World?</h3>
        <p>
          Jazz TutorG is an educational app designed for children but managed by adults. We do not offer products or services for purchase directly by children. If you are under 15, you may use Jazz TutorG only with the supervision and involvement of a parent or guardian.
        </p>
        <h3>8. Conditions of Use, Notices, and Revisions</h3>
        <p>
          If you choose to visit/use Jazz TutorG, your visit and any dispute over privacy is subject to this Notice and our Conditions of Use, including limitations on damages, resolution of disputes. If you have any concern about privacy at Jazz TutorG, please contact us with a thorough description, and we will try to resolve it. Our business changes constantly, and our Privacy Notice and the Conditions of Use will change also. We may e-mail periodic reminders of our notices and conditions, but you should check our Web site/Jazz TutorG frequently to see recent changes. Unless stated otherwise, our current Privacy Notice applies to all information that we have about you and your account. We stand behind the promises we make, however, and will never materially change our policies and practices to make them less protective of customer information collected in the past without the consent of affected customers.
        </p>

        <h3>9. Deletion of Data</h3>
        <p>
          If you want that the data collected while you used/visited Jazz TutorG may be deleted, you need to unsubscribe/logout the service via app/web. Once you unsubscribe/logout from the service all your data is flushed from our systems and if you visit/use the service again you will be treated as a new user<br></br>
          Also note : All data collected for Jazz TutorG app & web is only used for customer personalization and tracking and reporting. None of this given to any third party and is encrypted.
        </p>

      </div>
    </div>
  );
}

export default PrivacyPolicy;
