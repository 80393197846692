import {
  IactivateLPPolicy,
  ICheckUtmResp,
  IgetMetaDataAndFlow,
  IheaderEnrichmentPolicy,
  IPlanResp,
  IsendOtp,
} from "../@types/ApiResponses";
import { ApiNames } from "../constants/ApiNames";
// import { fetchLoginPost } from "./FetchAPI";
import {
  postRequest,
  getRequest,
  postRequestMulitiPart,
} from "./NetworkService";

export const checkOperatorService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.checkOperator,
    requestData
  );
  return data;
};

export const generateOTPService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.sendOTP,
    requestData
  );
  return data;
};

export const sendOtpService = async (requestData: { msisdn: string }) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.sendOTP,
    requestData
  );
  return data;
};

export const checkUserService = async (requestData: {
  msisdn: any;
  planId: string | null;
}) => {
  let endpoint =
    ApiNames.checkUser +
    "?msisdn=" +
    requestData.msisdn +
    "&plan_id=" +
    requestData.planId;
  let { data }: { data: any } = await getRequest(endpoint);
  return data;
};

export const unSubscribeService = async (requestData: {
  msisdn: any;
  planId: string | null;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.unsubscribeWeb,
    requestData
  );
  return data;
};

export const subscribeService = async (
  endpoint: string,
  requestData: {
    msisdn: string;
    otp: string;
    plan_id: number;
    utm_source: string;
    source: string;
  }
) => {
  let { data }: { data: IactivateLPPolicy } = await postRequest(
    endpoint,
    requestData
  );
  return data;
};

export const checkUtmService = async () => {
  let { data }: { data: ICheckUtmResp } = await getRequest(ApiNames.utmList);
  return data;
};
export const subscriptionService = async (requestData: {
  msisdn: string;
  otp: string;
  device_info: string;
  sub_package: string;
}) => {
  let { data }: { data: any } = await postRequest(
    ApiNames.subscribeWeb,
    requestData
  );
  return data;
};

export const getFlowService = async (endpoint: string) => {
  let { data }: { data: any } = await getRequest(endpoint);
  return data;
};
